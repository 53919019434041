button.choice {
  margin: 4px;
  border-width: 1px;
  padding: 6px 12px;

  &.selected {
    border-width: 4px;
    padding: 3px 9px; }

  &:hover {
    color: inherit;
    background-color: inherit; } }
